<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      "Go to store": "Ir para a loja virtual",
      Menu: "Menu",
      Dashboard: "Painel",
      Register: "Cadastrar",
      Courses: "Cursos",
      Store: "Loja",
      Products: "Produtos",
      Cart: "Carrinho",
      "My Orders": "Meus Pedidos",
      Wallet: "Carteira",
      Extract: "Extrato",
      Network: "Rede",
      Profit: "Rendimento",
      Activation: "Ativação",
      Balance: "Saldo",
      Voucher: "Voucher",
      Transfer: "Transferir",
      Pay: "Pagar",
      Withdrawal: "Sacar",
      "Withdrawal History": "Histórico de Saque",
      Sponsored: "Indicados",
      Team: "Equipe",
      Binary: "Binário",
      Reports: "Relatórios",
      "Bonus Referral": "Bônus Indicação",
      "Bonus Team": "Bônus Equipe",
      "Bonus Shopping": "Bônus Compras",
      Graduation: "Graduação",
      Support: "Suporte",
      Downloads: "Downloads",
      FAQ: "FAQ",
      Tickets: "Tickets",
      Points: "Pontos",
      "Monthly Activation": "Ativação Mensal",
    },
    es: {
      "Go to store": "Ir a la tienda virtual",
      Menu: "Menu",
      Dashboard: "Panel",
      Register: "Registro",
      Courses: "Cursos",
      Store: "Tienda",
      Products: "Productos",
      Cart: "Cesta",
      "My Orders": "Mis Pedidos",
      Wallet: "Cartera",
      Extract: "Extracto",
      Network: "Red",
      Profit: "Rendimiento",
      Activation: "Activación",
      Balance: "Saldo",
      Voucher: "Cupón",
      Transfer: "Transferir",
      Pay: "Pagar",
      Withdrawal: "Retirada",
      "Withdrawal History": "Historia de Retirada",
      Sponsored: "Patrocinados",
      Team: "Equipo",
      Binary: "Binario",
      Reports: "Informes",
      "Bonus Referral": "Bonus Patrocinio",
      "Bonus Team": "Bônus Equipo",
      "Bonus Shopping": "Bônus Compras",
      Graduation: "Graduación",
      Support: "Soporte",
      Downloads: "Descargas",
      FAQ: "FAQ",
      Tickets: "Tickets",
      Points: "Puntos",
      "Monthly Activation": "Activación Mensual",
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }

    this.getTotalBalance()
  },
  data() {
    return {
      total: {
        balance: 0,
      },
    };
  },
  methods: {
    getTotalBalance() {
      api
        .get('wallet/balance')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = response.data.balance
          }
        })
    },
  }
};
</script>

<template>
  <div id="sidebar-menu" class="pt-3">
    <div class="bg-soft-light rounded mb-3 p-4">
      <div class="mb-3">
        <h5 class="mb-0 font-size-14">Saldo disponível</h5>
        <h4 class="text-primary">{{ total.balance | currency}}</h4>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <div class="font-size-14">Status da conta</div>
        <div><span class="bg-success rounded px-2 font-size-14 text-white text-uppercase">ATIVO</span></div>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <div class="font-size-14">Documentos</div>
        <div v-if="account.user.documents && account.user.documents.status == 'pending'"><span class="bg-warning rounded px-2 font-size-14 text-white text-uppercase">PENDENTE</span></div>
        <div v-else-if="account.user.documents && account.user.documents.status == 'approved'"><span class="bg-success rounded px-2 font-size-14 text-white text-uppercase">APROVADO</span></div>
        <div v-else><span class="bg-danger rounded px-2 font-size-14 text-white text-uppercase">PENDENTE</span></div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="font-size-14">Tipo de conta</div>
        <div><span class="bg-primary rounded px-2 font-size-14 text-white text-uppercase">{{ account.user.package.name }}</span></div>
      </div>
      <div class="d-flex justify-content-between mt-2" v-if="account.user.sponsor.name">
        <div class="font-size-14">Assessor</div>
        <div><span class="bg-light rounded px-2 font-size-14 text-dark text-uppercase">{{ account.user.sponsor.name }}</span></div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-3" style="gap: 15px">
      <div class="flex-fill">
        <router-link tag="a" to="/wallet/deposits/new" class="btn btn-primary btn-lg btn-block">
          Depositar
        </router-link>
      </div>
      <div class="flex-fill">
        <router-link tag="a" to="/wallet/withdrawal" class="btn btn-light btn-lg btn-block">
          Sacar
        </router-link>
      </div>
    </div>
    <ul id="side-menu" class="metismenu list-unstyled">
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
          <span class="notranslate">{{ t("Dashboard") }}</span>
        </router-link>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z">
            </path>
            <path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z"></path>
          </svg>
          <span class="notranslate">Contratos</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/investments/contracts/new" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Novo Contrato") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/investments/contracts" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Meus Contratos") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M20 3H5C3.346 3 2 4.346 2 6v12c0 1.654 1.346 3 3 3h15c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19c-.552 0-1-.449-1-1V6c0-.551.448-1 1-1h15v3h-6c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h6.001v3H5zm15-9v4h-6v-4h6z">
            </path>
          </svg>
          <span class="notranslate">Depósitos</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/wallet/deposits/new" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Novo Depósito") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/wallet/deposits" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Meus Depósitos") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link tag="a" to="/wallet/extract" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 15c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92 0-1.12-.52-3-4-3-2 0-2-.63-2-1s.7-1 2-1 1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3 2 0 2 .68 2 1s-.62 1-2 1z">
            </path>
            <path d="M5 2H2v2h2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4h2V2H5zm13 18H6V4h12z"></path>
          </svg>
          <span class="notranslate">{{ t("Extract") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/wallet/withdrawal" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="24" height="24">
            <path
              d="M 15 1.0996094 C 13.975 1.0996094 12.949922 1.4895313 12.169922 2.2695312 L 7.1894531 7.25 L 7.3398438 7.25 C 8.6098437 7.25 9.7992188 7.740625 10.699219 8.640625 L 14.189453 12.130859 C 14.639453 12.570859 15.360547 12.570859 15.810547 12.130859 L 19.300781 8.640625 C 20.200781 7.740625 21.390156 7.25 22.660156 7.25 L 22.810547 7.25 L 17.830078 2.2695312 C 17.050078 1.4895313 16.025 1.0996094 15 1.0996094 z M 5.6894531 8.75 L 2.2695312 12.169922 C 0.70953125 13.729922 0.70953125 16.270078 2.2695312 17.830078 L 5.6894531 21.25 L 7.3398438 21.25 C 8.2098438 21.25 9.030625 20.910781 9.640625 20.300781 L 13.130859 16.810547 C 14.160859 15.780547 15.839141 15.780547 16.869141 16.810547 L 20.359375 20.300781 C 20.969375 20.910781 21.790156 21.25 22.660156 21.25 L 24.310547 21.25 L 27.730469 17.830078 C 29.290469 16.270078 29.290469 13.729922 27.730469 12.169922 L 24.310547 8.75 L 22.660156 8.75 C 21.790156 8.75 20.969375 9.0892188 20.359375 9.6992188 L 16.869141 13.189453 C 16.359141 13.699453 15.68 13.960938 15 13.960938 C 14.32 13.960938 13.640859 13.699453 13.130859 13.189453 L 9.640625 9.6992188 C 9.030625 9.0892187 8.2098437 8.75 7.3398438 8.75 L 5.6894531 8.75 z M 15 17.539062 C 14.7075 17.539062 14.414453 17.649141 14.189453 17.869141 L 10.699219 21.359375 C 9.7992188 22.259375 8.6098437 22.75 7.3398438 22.75 L 7.1894531 22.75 L 12.169922 27.730469 C 13.729922 29.290469 16.270078 29.290469 17.830078 27.730469 L 22.810547 22.75 L 22.660156 22.75 C 21.390156 22.75 20.200781 22.259375 19.300781 21.359375 L 15.810547 17.869141 C 15.585547 17.649141 15.2925 17.539062 15 17.539062 z" />
          </svg>
          <span class="notranslate">{{ t("Saques") }}</span>
        </router-link>
      </li>
      <li v-if="account.user.package.sponsored == 'yes'">
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z">
            </path>
            <path d="M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z">
            </path>
          </svg>
          <span class="notranslate">Rede</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/network/clients" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Meus Clientes") }}</span>
            </router-link>
          </li>
          <li v-if="account.user.package.team == 'yes'">
            <router-link tag="a" to="/network/managers" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Meus Assessores") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link tag="a" to="/account" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M12 10c1.151 0 2-.848 2-2s-.849-2-2-2c-1.15 0-2 .848-2 2s.85 2 2 2zm0 1c-2.209 0-4 1.612-4 3.6v.386h8V14.6c0-1.988-1.791-3.6-4-3.6z"></path>
            <path d="M19 2H5c-1.103 0-2 .897-2 2v13c0 1.103.897 2 2 2h4l3 3 3-3h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-5 15-2 2-2-2H5V4h14l.002 13H14z"></path>
          </svg>
          <span class="notranslate">{{ t("Perfil") }}</span>
        </router-link>
      </li>
      <li class="d-none">
        <router-link tag="a" to="/downloads" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="m12 16 4-5h-3V4h-2v7H8z"></path>
            <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path>
          </svg>
          <span class="notranslate">{{ t("Downloads") }}</span>
        </router-link>
      </li>
      <li class="d-none">
        <router-link tag="a" to="/faq" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z">
            </path>
            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z">
            </path>
          </svg>
          <span class="notranslate">{{ t("FAQ") }}</span>
        </router-link>
      </li>
      <li class="d-none">
        <router-link tag="a" to="/tickets" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z">
            </path>
          </svg>
          <span class="notranslate">{{ t("Tickets") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/logout" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
            <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
          </svg>
          <span class="notranslate">{{ t("Sair") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: 0.9rem 1.5rem 0.7rem !important;
}

.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}

.py-2 i {
  margin-top: -4px;
}
</style>